import { Link } from "gatsby";
import resolveLink from "../../utils/resolveLink";
import React from "react";
import {
  Index,
  Configure,
  connectHits,
  Pagination,
} from "react-instantsearch-dom";
import Heading from "src/components/Heading";
import { SearchIcon } from "@heroicons/react/solid";

const PageHit = ({ hit }) => {
  return (
    <Link className="group relative" to={resolveLink(`/${hit.full_slug}`)}>
      <div className="relative w-full min-h-80 bg-gray-200 overflow-hidden group-hover:opacity-75 group-hover:-translate-y-2 duration-300 aspect-[750/500] rounded-lg">
        {hit.featured_image && (
          <img
            className="w-full h-full object-center object-cover lg:w-full lg:h-full"
            src={`${hit.featured_image.filename}/m/350x0`}
            alt={hit.featured_image.alt}
          />
        )}
      </div>
      <div className="mt-4 flex justify-between flex-col">
        <span className="block uppercase text-teal text-[11px] mb-1 tracking-widest">
          {hit.component.replace(/-/g, " ").replace(/_/g, " ")}
        </span>
        <h3 className="text-blue text-base font-semibold group-hover:text-teal duration-300">
          {hit.name}
        </h3>
      </div>
    </Link>
  );
};

const CustomHits = ({ hits }) => (
  <>
    <Configure hitsPerPage={100} />
    <div className="max-w-7xl mx-auto mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
      {hits.map(hit => (
        <div key={hit.objectID}>
          <PageHit hit={hit} />
        </div>
      ))}
    </div>
  </>
);

const CustomHitsWithPagination = connectHits(CustomHits);

const HitsInIndex = ({ index }) => (
  <Index indexName={index.name}>
    <CustomHitsWithPagination />
  </Index>
);

const SearchResult = ({ indices, show }) => (
  <div>
    <Heading size="h6" className="text-blue font-extrabold">
      Insights &amp; Resources
    </Heading>
    {indices.map((index) => (
      <HitsInIndex index={index} key={index.name} />
    ))}
  </div>
);

export default SearchResult;
